import React, { useContext, useState } from "react"
import { AppContext } from "../../contexts/AppContext"
import UserPodcastForm from "./form"
import UserPodcastList from "./list"
import * as St from "../Styled"

// Component that contains complete page data for user audios
const ContentMyAudio = props => {
  let { device } = useContext(AppContext)

  const [showForm, setShowForm] = useState({
    show: false,
    edit: false,
  })
  const [formValues, setFormValues] = useState({
    id: "",
    title: "",
    about: "",
    uri: "",
    preview: "",
  })
  const [forceFetch, setForceFetch] = useState(false)
  return (
    <St.ContentWrapper>
      <St.ContentSection>
        <St.LeftAdSection />
        <St.CenterContentWrapper>
          <St.DataTopHeadingWrapper>
            <St.DataTopHeading>
              {showForm.show
                ? showForm.edit
                  ? `Update Podcast`
                  : `Add Podcast`
                : `Podcasts`}
            </St.DataTopHeading>
            <St.TopRightButton>
              {!showForm.show ? (
                <button
                  type="button"
                  onClick={() => {
                    setShowForm({
                      show: true,
                      edit: false,
                    })
                    setFormValues({})
                  }}
                >
                  Add Podcast
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() =>
                    setShowForm({
                      show: false,
                      edit: false,
                    })
                  }
                >
                  Back to List
                </button>
              )}
            </St.TopRightButton>
          </St.DataTopHeadingWrapper>

          <St.CenterContent>
            {showForm.show ? (
              <UserPodcastForm
                device={device}
                setShowForm={setShowForm}
                formValues={formValues}
                setForceFetch={setForceFetch}
                style={{ alignSelf: "center" }}
              />
            ) : (
              <UserPodcastList
                {...{
                  setShowForm,
                  setFormValues,
                  setForceFetch,
                  forceFetch,
                }}
              />
            )}
          </St.CenterContent>

          <St.BottomAdSection />
        </St.CenterContentWrapper>
        <St.RightAdSection />
      </St.ContentSection>
    </St.ContentWrapper>
  )
}

export default ContentMyAudio
