import React, { useContext } from "react"
import { AppContext } from "../contexts/AppContext"
import NotAuthorized from "../components/NotAuthorized"
import { ROLE_STATIONADMIN, ROLE_ARTIST } from "../constants/props"

import ContentUserPodcasts from "../components/ContentUserPodcasts"

const UserPodcasts = () => {
  let { userState } = useContext(AppContext)
  if (
    userState &&
    userState.role &&
    (userState.role.type === ROLE_STATIONADMIN || userState.role.type === ROLE_ARTIST)
  ) {
    return (
      <React.Fragment>
        <ContentUserPodcasts />
      </React.Fragment>
    )
  } else {
    return <NotAuthorized>You are not authorized to see contents on this page</NotAuthorized>
  }
}

export default UserPodcasts
