import gql from "graphql-tag"

export const QUERY_ALL_STATION_SHOWS = gql`
  query AllStationShows($userID: ID!) {
    shows(where: { owner: $userID }) {
      id
      title
      about
      image {
        id
        url
      }
      episodes {
        id
        day
        from
        to
        referenceDate
      }
      station {
        id
        title
      }
      presenter {
        id
        title
      }
    }
  }
`

export const ADD_STATION_SHOW = gql`
  mutation createShow($input: createShowInput) {
    createShow(input: $input) {
      show {
        id
        title
        image {
          id
          url
        }
        about
        presenter {
          id
        }
        station {
          id
        }
      }
    }
  }
`
export const MUTATION_UPDATE_SHOW = gql`
  mutation updateShow($input: updateShowInput) {
    updateShow(input: $input) {
      show {
        id
        title
        image {
          id
          url
        }
        about
        presenter {
          id
        }
        station {
          id
        }
      }
    }
  }
`

export const MUTATION_DELETE_SHOW = gql`
  mutation deleteShow($input: deleteShowInput) {
    deleteShow(input: $input) {
      show {
        id
      }
    }
  }
`

export const MUTATION_ADD_EPISODE = gql`
  mutation AddEpisode($input: createEpisodeInput) {
    createEpisode(input: $input) {
      episode {
        id
      }
    }
  }
`
export const MUTATION_DELETE_EPISODE = gql`
  mutation DeleteEpisode($input: deleteEpisodeInput) {
    deleteEpisode(input: $input) {
      episode {
        id
      }
    }
  }
`
export const QUERY_ALL_SHOWS_OPTIONS = gql`
  query AllStationShows($userID: ID!) {
    shows(where: { owner: $userID }) {
      id
      title
    }
  }
`

export const QUERY_NEXT_EPISODE_BY_DATE = gql`
  query AllStationEpisodes($stationID: ID!, $referenceDate: DateTime!) {
    episodes(
      where: { station: $stationID, referenceDate_gt: $referenceDate }
      limit: 1
      sort: "referenceDate:asc"
    ) {
      id
      referenceDate
      from
      to
      show {
        id
        title
        presenter {
          id
          title
        }
      }
    }
  }
`
export const QUERY_NEXT_EPISODE = gql`
  query AllStationEpisodes($stationID: ID!) {
    episodes(
      where: { station: $stationID }
      limit: 1
      sort: "referenceDate:asc"
    ) {
      id
      referenceDate
      from
      to
      show {
        id
        title
        presenter {
          id
          title
        }
      }
    }
  }
`
