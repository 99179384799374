import React, { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import { AppContext } from "../../contexts/AppContext"
import { useMutation } from "@apollo/client"
import * as St from "../Styled"
import {
  CREATE_USER_MEDIACONTENT,
  MUTATION_UPDATE_USER_MEDIA,
} from "../../queries/page/mediacontent"

import axios from "axios"
import auth from "../../auth/auth"
import { NotificationManager } from "react-notifications"

import {
  awsStationPodcastsPath,
  ROLE_STATIONADMIN,
  ROLE_ARTIST,
  awsArtistPodcastsPath,
} from "../../constants/props"

import useButtonLoader from "../../hooks/useButtonLoader"
import { QUERY_ALL_PRESENTER_OPTIONS } from "../../queries/page/presenter"
import { QUERY_ALL_SHOWS_OPTIONS } from "../../queries/page/show"
import ProgressContent from "../ProgressBar"
import Query from "../Query"

// Component contains audio form (Add audio form/Edit audio form)
const UserAudioForm = props => {
  let { device, userState } = useContext(AppContext)
  const { handleSubmit, register, errors, reset } = useForm({
    defaultValues: props.formValues,
  })
  const [createMedia] = useMutation(CREATE_USER_MEDIACONTENT)
  const [updateAudio] = useMutation(MUTATION_UPDATE_USER_MEDIA)
  const [coverImage, setCoverImage] = useState({
    preview: "",
    raw: "",
    name: "",
  })
  const [audioFile, setAudioFile] = useState({
    raw: undefined,
    name: undefined,
  })
  const [progressOfImage, setProgressOfImage] = useState({
    count: 0,
    title: "",
  })
  const [progressOfAudio, setProgressOfAudio] = useState({
    count: 0,
    title: "",
  })

  const [loaderElement, setLoader] = useButtonLoader(
    props.formValues.id ? "Update Podcast" : "Add Podcast",
    "Processing..."
  )
  const basePrefix = `${process.env.STRAPI_SERVER_URL}` // SERVER BASE
  const [presenterSelected, setPresenterSelected] = useState(false)
  const [showSelected, setShowSelected] = useState(false)

  let userRoleType =
    userState && userState.role && userState.role.type
      ? userState.role.type
      : undefined

  const handleAudioChange = e => {
    if (e.target.files.length) {
      setAudioFile({
        raw: e.target.files[0],
        name: e.target.files[0].name,
      })
    }
  }
  const handleChange = e => {
    if (e.target.files.length) {
      setCoverImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
        name: e.target.files[0].name,
      })
    }
  }

  const onChangePresenter = event => {
    var presenterID = event.target.value
    if (presenterID && presenterID.trim().length > 0) {
      setPresenterSelected(true)
    } else {
      setPresenterSelected(false)
    }
  }

  const onChangeShow = event => {
    var showID = event.target.value
    if (showID && showID.trim().length > 0) {
      setShowSelected(true)
    } else {
      setShowSelected(false)
    }
  }

  const uploadCoverImage = (coverImageFile, id) => {
    // cover image of audio file was updated during update operation

    if (!userRoleType) {
      return new Promise((resolve, reject) => {
        reject(new Error("No role user"))
      })
    }

    let coverImagePath =
      userRoleType === ROLE_STATIONADMIN
        ? awsStationPodcastsPath(userState.station.title)
        : userRoleType === ROLE_ARTIST
        ? awsArtistPodcastsPath(userState.firstname + " " + userState.lastname)
        : undefined

    const coverFormData = new FormData()
    coverFormData.append("files", coverImageFile)
    coverFormData.append("path", coverImagePath)
    coverFormData.append("ref", "mediacontent")
    coverFormData.append("refId", id)
    coverFormData.append("field", "image")
    return axios.post(`${basePrefix}/upload`, coverFormData, {
      headers: {
        Authorization: "Bearer " + auth.getToken(),
      },
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        setProgressOfImage({
          count: percentCompleted - 0.05 * percentCompleted,
          title: "Uploading " + coverImage.name,
        })
      },
    })
  }

  const uploadAudioFile = (audioFile, mediaContentID) => {
    if (!userRoleType) {
      return new Promise((resolve, reject) => {
        reject(new Error("No role user"))
      })
    }

    let mediaFilePath =
      userRoleType === ROLE_STATIONADMIN
        ? awsStationPodcastsPath(userState.station.title)
        : userRoleType === ROLE_ARTIST
        ? awsArtistPodcastsPath(userState.firstname + " " + userState.lastname)
        : undefined

    const audioFormData = new FormData()
    audioFormData.append("files", audioFile)
    audioFormData.append("path", mediaFilePath)
    audioFormData.append("ref", "mediacontent")
    audioFormData.append("refId", mediaContentID)
    audioFormData.append("field", "mediaUri")

    return axios.post(`${basePrefix}/upload`, audioFormData, {
      headers: {
        Authorization: "Bearer " + auth.getToken(),
      },
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        setProgressOfAudio({
          count: percentCompleted - 0.05 * percentCompleted,
          title: "Uploading " + audioFile.name,
        })
      },
    })
  }

  const updateAudioData = (data, where) => {
    return updateAudio({
      variables: {
        input: {
          data,
          where,
        },
      },
      context: {
        headers: {
          Authorization: "Bearer " + auth.getToken(),
        },
      },
    })
  }

  const showNotification = (msg, success) => {
    success ? NotificationManager.success(msg) : NotificationManager.error(msg)
    setLoader(false)
    if (success) {
      // reset and hide form if success
      reset()
      props.setShowForm({
        show: false,
        edit: false,
      })
      // as data is updated or created force fetch data from server
      props.setForceFetch(true)
    }
  }

  const onSubmit = values => {
    if (values.id) {
      // Audio is updated by user
      setLoader(true)
      // Update basic data in collection first
      updateAudioData(
        {
          title: values.title,
          about: values.about,
          presenter:
            values.presenter && values.presenter.id
              ? values.presenter.id
              : null,
          show: values.show && values.show.id ? values.show.id : null,
        },
        {
          id: values.id,
        }
      ).then(() => {
        if (coverImage.raw) {
          uploadCoverImage(coverImage.raw, values.id)
            .then(response => {
              setProgressOfImage({
                count: 100,
                title: "Image Successfully Uploaded",
              })
              if (audioFile.raw) {
                uploadAudioFile(audioFile.raw, values.id)
                  .then(mediaUploadResponse => {
                    setProgressOfAudio({
                      count: 100,
                      title: "Audio Successfully Uploaded",
                    })
                    if (
                      mediaUploadResponse.data &&
                      mediaUploadResponse.data.length
                    ) {
                      // Set URI as it is currently used every where, need to change all code to use mediaUri
                      updateAudioData(
                        {
                          uri: mediaUploadResponse.data[0].url,
                        },
                        {
                          id: values.id,
                        }
                      )
                      showNotification("Audio data changed ...", true)
                    }
                  })
                  .catch(() => {
                    showNotification("Audio file upload failed...", false)
                  })
              } else {
                showNotification("Audio data changed ...", true)
              }
            })
            .catch(() => {
              showNotification("Cover image upload failed...", false)
            })
        } else {
          if (audioFile.raw) {
            uploadAudioFile(audioFile.raw, values.id)
              .then(mediaUploadResponse => {
                if (
                  mediaUploadResponse.data &&
                  mediaUploadResponse.data.length
                ) {
                  // Set URI as it is currently used every where, need to change all code to use mediaUri
                  updateAudioData(
                    {
                      uri: mediaUploadResponse.data[0].url,
                    },
                    {
                      id: values.id,
                    }
                  )
                  showNotification("Audio data changed ...", true)
                }
              })
              .catch(() => {
                showNotification("Audio file upload failed...", false)
              })
          } else {
            showNotification("Audio data changed ...", true)
          }
        }
      })
    } else {
      // User has created a new content
      setLoader(true)
      createMedia({
        variables: {
          input: {
            data: {
              title: values.title,
              about: values.about,
              premium: false,
              featured: false,
              confirmed: false,
              contenttype: "audio",
              owner: userState.id,
              station: userState.stationID,
              artist: userState.artist ? userState.artist.id : null,
              uri: " ",
              presenter:
                values.presenter && values.presenter.id
                  ? values.presenter.id
                  : undefined,
              show: values.show && values.show.id ? values.show.id : undefined,
            },
          },
        },
        context: {
          headers: {
            Authorization: "Bearer " + auth.getToken(),
          },
        },
      }).then(response => {
        if (
          response.data &&
          response.data.createMediacontent &&
          response.data.createMediacontent.mediacontent
        ) {
          const mediaID = response.data.createMediacontent.mediacontent.id
          uploadCoverImage(coverImage.raw, mediaID)
            .then(() => {
              setProgressOfImage({
                count: 100,
                title: "Image Successfully Uploaded",
              })
              uploadAudioFile(audioFile.raw, mediaID)
                .then(mediaUploadResponse => {
                  setProgressOfAudio({
                    count: 100,
                    title: "Audio Successfully Uploaded",
                  })
                  if (
                    mediaUploadResponse.data &&
                    mediaUploadResponse.data.length
                  ) {
                    // Set URI as it is currently used every where, need to change all code to use mediaUri
                    updateAudioData(
                      {
                        uri: mediaUploadResponse.data[0].url,
                      },
                      {
                        id: mediaID,
                      }
                    )
                  }
                  showNotification("Audio data saved...", true)
                })
                .catch(() => {
                  showNotification("Audio file upload failed...", false)
                })
            })
            .catch(() => {
              showNotification("Cover image upload failed...", false)
            })
        }
      })
    }
  }

  const formValues = props.formValues

  let coverImageURL =
    formValues && formValues.image && formValues.image.url
      ? !formValues.image.url.startsWith("http")
        ? basePrefix + formValues.image.url
        : formValues.image.url
      : undefined

  let audioURL = formValues && formValues.uri ? formValues.uri : undefined

  let audioFileName =
    formValues && formValues.mediaFileName
      ? formValues.mediaFileName
      : undefined

  //let audioFileName = formValues && formValues.

  return (
    <St.Form
      ismobile={device === "mobile"}
      onSubmit={handleSubmit(onSubmit)}
      enctype="multipart/form-data"
    >
      <div>
        {formValues && formValues.id && (
          <St.FormInput name="id" type="hidden" ref={register} />
        )}
        <St.Label>Title</St.Label>
        <St.FormInput
          name="title"
          aria-invalid={errors.title ? "true" : "false"}
          aria-describedby="titleError"
          ref={register({
            required: "Please add title for this audio",
          })}
        />

        {errors.title && <St.ErrorText>{errors.title.message}</St.ErrorText>}
      </div>
      <div>
        <St.Label>Audio (MP3)</St.Label>

        {audioURL ? (
          <>
            <St.AudioFileLabel htmlFor="user-audio">
              {audioFile.name ? (
                <span>{audioFile.name} (Selected)</span>
              ) : audioFileName ? (
                <span>{audioFileName}</span>
              ) : (
                <span>Click here to change mp3</span>
              )}
            </St.AudioFileLabel>
          </>
        ) : (
          <>
            <St.AudioFileLabel htmlFor="user-audio">
              {audioFile.name ? (
                <span>{audioFile.name} (Selected)</span>
              ) : (
                <span>Click here to add mp3</span>
              )}
            </St.AudioFileLabel>
          </>
        )}
        <St.FormInputFile
          id="user-audio"
          name="audioFile"
          aria-invalid={errors.audioFile ? "true" : "false"}
          aria-describedby="audioFileError"
          accept="audio/mp3"
          type="file"
          onChange={handleAudioChange}
          ref={
            !audioURL
              ? register({
                  required: "Please select mp3",
                })
              : undefined
          }
        />

        {errors.audioFile && (
          <St.ErrorText>{errors.audioFile.message}</St.ErrorText>
        )}
      </div>
      <div>
        <St.Label>Cover Image (Recommended Size: 250 X 250 pixels)</St.Label>
        <St.FileTypeLabel htmlFor="upload-cover">
          {coverImage.preview ? (
            <St.Image src={coverImage.preview} alt="dummy"></St.Image>
          ) : coverImageURL ? (
            <St.Image src={coverImageURL} alt="dummy"></St.Image>
          ) : (
            <> Click here to Upload Cover Image </>
          )}
        </St.FileTypeLabel>
        <St.FormInputFile
          id="upload-cover"
          name="coverImage"
          aria-invalid={errors.coverImage ? "true" : "false"}
          aria-describedby="coverImageError"
          accept="image/png, image/jpeg"
          type="file"
          onChange={handleChange}
          ref={
            !coverImageURL
              ? register({
                  required: "Please add cover image",
                })
              : undefined
          }
        />

        {errors.coverImage && (
          <St.ErrorText>{errors.coverImage.message}</St.ErrorText>
        )}
      </div>
      {userRoleType === ROLE_STATIONADMIN && !showSelected && (
        <div>
          <St.Label>Presenter</St.Label>
          <St.FormSelect
            name="presenter.id"
            aria-invalid={errors.presenter ? "true" : "false"}
            aria-describedby="presenterError"
            ref={register()}
            onChange={onChangePresenter}
          >
            <option key={"empty"} value="">
              -- Select presenter --
            </option>
            {props.presenters &&
              props.presenters.length &&
              props.presenters.map((presenter, i) => (
                <option key={i} value={presenter.id}>
                  {presenter.title}
                </option>
              ))}
          </St.FormSelect>
        </div>
      )}

      {userRoleType === ROLE_STATIONADMIN && !presenterSelected && (
        <div>
          <St.Label>Shows</St.Label>
          <St.FormSelect
            name="show.id"
            aria-invalid={errors.show ? "true" : "false"}
            aria-describedby="showError"
            ref={register()}
            onChange={onChangeShow}
          >
            <option key={"empty"} value="">
              -- Select show --
            </option>
            {props.shows &&
              props.shows.length &&
              props.shows.map((show, i) => (
                <option key={i} value={show.id}>
                  {show.title}
                </option>
              ))}
          </St.FormSelect>
        </div>
      )}

      <div>
        <St.Label>About</St.Label>
        <St.FormTextarea
          name="about"
          placeholder="Write something about this podcast..."
          aria-invalid={errors.about ? "true" : "false"}
          aria-describedby="aboutError"
          ref={register({
            required: "Please write something about this podcast",
          })}
        />
        {errors.about && <St.ErrorText>{errors.about.message}</St.ErrorText>}
      </div>
      <St.SubmitButton ref={loaderElement}>Submit</St.SubmitButton>
      {progressOfImage.count < 100 && (
        <ProgressContent
          progress={progressOfImage.count}
          title={progressOfImage.title}
        />
      )}
      {progressOfAudio.count < 100 && (
        <ProgressContent
          progress={progressOfAudio.count}
          title={progressOfAudio.title}
        />
      )}
    </St.Form>
  )
}

const UserAudioFormContainer = props => {
  let { userState } = useContext(AppContext)

  return (
    <Query
      query={QUERY_ALL_PRESENTER_OPTIONS}
      variables={{
        userID: userState.id,
      }}
      fetchPolicy="network-only"
    >
      {({ data: { presenters } }) => {
        return (
          <Query
            query={QUERY_ALL_SHOWS_OPTIONS}
            variables={{
              userID: userState.id,
            }}
            fetchPolicy="network-only"
          >
            {({ data: { shows } }) => {
              return (
                <UserAudioForm
                  {...props}
                  presenters={presenters && presenters.length ? presenters : []}
                  shows={shows && shows.length ? shows : []}
                />
              )
            }}
          </Query>
        )
      }}
    </Query>
  )
}

export default UserAudioFormContainer
